import { Colors } from "./types";

export const baseColors = {
  failure: "#ED4B9E",
  primary: "#000",
  primaryBright: "#53DEE9",
  primaryDark: "#53DEE9",
  secondary: "#000",
  success: "#000000",
  warning: "#000",
  white: "#ffffff",
  baby_blue: "#000",
};

export const additionalColors = {
  binance: "#F0B90B",
  overlay: "#452a7a",
  gold: "#FFC700",
  silver: "#B2B2B2",
  bronze: "#E7974D",
};

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: "#000",
  backgroundDisabled: "#E9EAEB",
  backgroundAlt: "#dd1c07",
  backgroundAlt2: "#1e1e1e",
  cardBorder: "#1e1e1e",
  contrast: "#1e1e1e",
  dropdown: "#fff",
  dropdownDeep: "#000",
  invertedContrast: "#1e1e1e",
  input: "#eeeaf4",
  inputSecondary: "#d7caec",
  tertiary: "#fff",
  text: "#000",
  textDisabled: "#BDC2C4",
  textSubtle: "#000",
  disabled: "#E9EAEB",
  gradients: {
    bubblegum: "#000",
    inverseBubblegum: "0e1c39 ",
    cardHeader: "#1e1e1e",
    blue: "#000",
    violet: "0e1c39",
    violetAlt: "0e1c39",
    gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
  },
};

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: "#000",
  backgroundDisabled: "#E9EAEB",
  backgroundAlt: "#fff",
  backgroundAlt2: "#fff",
  cardBorder: "#000",
  contrast: "#1e1e1e",
  dropdown: "#fff",
  dropdownDeep: "#000",
  invertedContrast: "#1e1e1e",
  input: "#eeeaf4",
  inputSecondary: "#d7caec",
  tertiary: "#EFF4F5",
  text: "#000",
  textDisabled: "#BDC2C4",
  textSubtle: "#000",
  disabled: "#E9EAEB",
  gradients: {
    bubblegum: "#000",
    inverseBubblegum: "0e1c39 ",
    cardHeader: "#fff",
    blue: "#000",
    violet: "0e1c39",
    violetAlt: "0e1c39",
    gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
  },
};
