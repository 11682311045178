import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face
  {
    font-family: 'Garet-Book';
    src: url(/fonts/Garet-Book.ttf) format('trueType');
  }
  body {
    
    background-image: url("/images/bkg.png");

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
