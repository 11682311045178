import { BigNumber } from '@ethersproject/bignumber'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { CHAIN_ID } from './networks'
import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto CAKE</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/logo.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake CAKE</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/logo.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },

} as const

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.boneshi,
    earningToken: serializedTokens.boneshi,
    contractAddress: {
      109: '0x94168CDa7119e55Dd379E09d4cf8a2A2Ce126195',
      97: '0x4869C68F2498A507e524516B3ef539215517fD96',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '100',
    sortOrder: 1,
    isFinished: false,
    length: "Forever"
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.boneshi,
    earningToken: serializedTokens.boneshi,
    contractAddress: {
      109: '0xf66e38927754e47d0F487415F683C86cd4517b8a',
      97: '0xe56b71518Ce4cb9916B98e99ec10d09665f5F369',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '2.3',
    sortOrder: 1,
    isFinished: false,
    length: "4 weeks"
  
  },

  {
    sousId: 2,
    stakingToken: serializedTokens.boneshi,
    earningToken: serializedTokens.boneshi,
    contractAddress: {
      109: '0xFD906A52555238ECaD5aF532A0FC0B858cB940B7',
      97: '0xFD906A52555238ECaD5aF532A0FC0B858cB940B7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '2.7',
    sortOrder: 1,
    isFinished: false,
    length: "6 weeks"
  
  },

 

].filter((p) => !!p.contractAddress[CHAIN_ID])

export default pools

